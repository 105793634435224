import axios from 'axios';
import { resourceTimeout } from '../constants';
import { authStore } from '../../store/auth';
import { userStore } from '../../store/user';

const { REACT_APP_FIREBASE_CLOUD_API } = process.env;

const API = axios.create({ baseURL: REACT_APP_FIREBASE_CLOUD_API, timeout: resourceTimeout });

const handleRequest = async (config) => {
  const auth = authStore.getState();

  const accessToken = auth?.accessToken;
  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const handleError = async (error) => {
  const status = error?.request?.status;

  if (status === 403) {
    const auth = authStore.getState();
    const user = userStore.getState();

    // logout user
    auth.handleLogout();
    user.resetUser();
  }
};

API.interceptors.request.use(handleRequest);
API.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
);

export default API;
