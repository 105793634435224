import {
  collection, doc, getDoc, getDocs, setDoc, addDoc, deleteDoc,
} from 'firebase/firestore';
import { isNull } from 'lodash-es';
import { db } from '../../../config/firebase';
// eslint-disable-next-line import/no-cycle
import { uploadFile } from '../storage';

const collectionName = 'document';

const docCollRef = collection(db, collectionName);

// eslint-disable-next-line import/prefer-default-export
export const fetchDocuments = async () => {
  const snap = await getDocs(docCollRef);

  const documents = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const document = {
      id, ...data,
    };

    documents.push(document);
  });
};

export const fetchDocumentById = async ({ documentId }) => {
  const docRef = doc(db, collectionName, documentId);
  const snap = await getDoc(docRef);

  const data = snap.data();

  return data;
};

export const updateDocument = async ({ data, documentId }) => {
  const docRef = doc(db, collectionName, documentId);

  const lastUpdateAt = new Date().toISOString();

  await setDoc(docRef, { ...data, lastUpdateAt }, { merge: true });
};

export const deleteFileFromDocument = async ({ file, documentId }) => {
  let documentData = await fetchDocumentById({ documentId });
  const { files } = documentData;

  const filterFiles = files.filter((f) => f !== file);

  await updateDocument({ data: { files: filterFiles }, documentId });

  documentData = await fetchDocumentById({ documentId });

  return documentData;
};

export const createDocument = async (data) => {
  const coll = collection(db, collectionName);
  const createdAt = new Date().toISOString();

  const { thumbnailUrl, ...restData } = data;
  const { id } = await addDoc(coll, { ...restData, thumbnailUrl: null, createdAt });

  if (!isNull(thumbnailUrl)) {
    // eslint-disable-next-line no-param-reassign
    data.thumbnailUrl = await uploadFile({ file: thumbnailUrl, path: `documents/${id}` });
  }

  await updateDocument({ data: { id, thumbnailUrl: data.thumbnailUrl }, documentId: id });

  return { id };
};

export const deleteDocument = async ({ documentId }) => {
  const docRef = doc(db, collectionName, documentId);
  await deleteDoc(docRef);
};
