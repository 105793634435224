/* eslint-disable no-console */
import API from './_instance';
import { endpoints } from './constants';
import { OS_API_KEY } from '../constants';

export const fetchUsers = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, vesselId, companyId, orderBy = 'fullName', isDisabled,
}) => {
  const { data } = await API.get(endpoints.users, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      vesselId,
      companyId,
      orderBy,
      isDisabled,
    },
  });

  return data;
};

export const fetchCompanies = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'name',
}) => {
  const { data } = await API.get(endpoints.companies, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      orderBy,
    },
  });
  return data;
};

export const fetchVessels = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, companyId, orderBy = 'name',
}) => {
  const { data } = await API.get(endpoints.vessels, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      companyId,
      orderBy,
    },
  });
  return data;
};

export const fetchDocuments = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'title',
}) => {
  const { data } = await API.get(endpoints.documents, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      orderBy,
    },
  });
  return data;
};

export const dataContainsEmergency = (data) => data.map((report) => {
  const keys = Object.keys(report);
  const containsEmergency = keys.some((key) => key.includes('checkbox') && report[key] === false) || keys.some((key) => key.includes('radio_value') && report[key] === '0');
  const containsCommentAndNotEmergency = !containsEmergency && keys.some((key) => (key.includes('input_comment1') || key.includes('input_comment_main')) && report[key]);
  return {
    ...report,
    containsEmergency: !!containsEmergency,
    containsCommentAndNotEmergency: !!containsCommentAndNotEmergency,
  };
});

export const fetchTopTenReports = async ({
  category, vesselId, companyId,
}) => {
  const { data } = await API.get(endpoints.reportsTen, {
    params: {
      category,
      vesselId,
      companyId,
    },
  });

  if (!Array.isArray(data.data)) {
    console.error('Unexpected API response:', data.data);
    return [];
  }

  const newData = dataContainsEmergency(data.data);

  data.data = newData;

  return data;
};

export const fetchAllReports = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'submittedAt', vesselId, companyId,
}) => {
  const { data } = await API.get(endpoints.reports, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      orderBy,
      vesselId,
      companyId,
    },
  });

  if (!Array.isArray(data.data)) {
    console.error('Unexpected API response:', data.data);
    return [];
  }

  const newData = dataContainsEmergency(data.data);

  data.data = newData;

  return data;
};

export const fetchReportDetails = async ({
  reportId,
}) => {
  const { data } = await API.get(endpoints.reportsDetails, {
    params: {
      reportId,
    },
  });

  return data;
};

export const fetchReportsByCat = async ({
  search, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'submittedAt', vesselId, companyId, categoryId = 'inspection',
}) => {
  const { data } = await API.get(endpoints.reportsCat, {
    params: {
      search,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      orderBy,
      vesselId,
      companyId,
      categoryId,
    },
  });

  if (!Array.isArray(data.data)) {
    console.error('Unexpected API response:', data.data);
    return [];
  }

  const newData = dataContainsEmergency(data.data);

  data.data = newData;

  return data;
};

export const fetchUserNotifications = async ({ oneSignalUserId }) => {
  const response = await API.get(
    `/get-notifications/${oneSignalUserId}`,
    {
      headers: {
        Authorization: `Basic ${OS_API_KEY}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );

  if (!response) {
    throw new Error('No response received from the API.');
  }

  if (response.status !== 200) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const emailFeedback = async (category, description, user) => {
  try {
    const response = await API.post(endpoints.feedback, { category, description, user });
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending email:', error);
    throw error;
  }
};

export const emailReport = async (data, attachments) => {
  try {
    const response = await API.post(endpoints.emailReport, { data, attachments });
    const { data: responseData } = response || {};
    return responseData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending email:', error);
    throw error;
  }
};

const pollAuthentication = async (authRequestId) => {
  const pollResponse = await API.post(
    '/auth/pollAuth',
    { authRequestId },
    {
      headers: {
        Authorization: `Basic ${OS_API_KEY}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );

  if (!pollResponse) {
    throw new Error('auth/no-connection');
  }

  if (pollResponse.status !== 200) {
    throw new Error('auth/no-connection');
  }

  const data = await pollResponse;
  if (data) {
    const { token, waitingForUserInput } = data.data;
    return { token, waitingForUserInput };
  }
  return { token: null, waitingForUserInput: true };
};

export const startAuth = async (phoneNumber, isApp) => {
  const response = await API.post(
    '/auth/startAuth',
    { phoneNumber, isApp },
    {
      headers: {
        Authorization: `Basic ${OS_API_KEY}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );

  if (!response) {
    throw new Error('auth/no-connection');
  }

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  const data = await response;
  const { authRequestId } = data.data;

  let newToken = null;
  let foundError = null;

  if (authRequestId) {
    while (newToken === null && !foundError) {
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((r) => setTimeout(r, 2000));
      // eslint-disable-next-line no-await-in-loop
      const { token, waitingForUserInput, error } = await pollAuthentication(authRequestId);

      if (error) {
        foundError = true;
      }

      if (!waitingForUserInput) {
        newToken = token;
        break;
      }
    }
  }

  return newToken;
};

export const deleteUser = async ({ id, disabled }) => {
  const response = await API.delete(
    `/users/deleteUser/${id}/${disabled}`,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );

  if (!response) {
    throw new Error('auth/no-connection');
  }

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  const data = await response;
  return data;
};

export const startAuthById = async (userId) => {
  const response = await API.post(
    '/users/auth',
    { userId },
    {
      headers: {
        Authorization: `Basic ${OS_API_KEY}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );

  if (!response) {
    throw new Error('auth/no-connection');
  }

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  const res = await response;

  if (res.data.token) {
    const loginToken = res.data.token;
    return loginToken;
  }

  return 'error';
};

export const fcmSendUsers = async (data) => {
  try {
    const response = await API.post(endpoints.fcmSendUsers, data);
    const { data: responseData } = response || {};

    if (!response) {
      throw new Error('error/no-registered-token');
    }

    return responseData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending user notifications:', error);
    throw error;
  }
};

export const fcmSendUser = async (data) => {
  try {
    const response = await API.post(endpoints.fcmSendUser, data);
    const { data: responseData } = response || {};

    if (!response) {
      throw new Error('error/no-registered-token');
    }

    return responseData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending single user notification:', error);
    throw error;
  }
};
